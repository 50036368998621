import React from 'react';
import { Link, Route, Routes } from "react-router-dom";
import AtesmanReadabilityCalculator from './Stuff/AtesmanReadabilityCalculator';
import RiemannZeta from './Stuff/RiemannZeta';
import styles from '../styles';

const StuffPage: React.FC = () => {
    return (
        <>
            <nav style={styles.nav}>
                <Link to="readability" style={styles.button}>Ateşman Readability Calculator</Link>
                <Link to="riemann-zeta" style={styles.button}>Riemann Zeta Function Plotter</Link>
            </nav>

            <Routes>
                <Route path="readability" element={<AtesmanReadabilityCalculator />} />
                <Route path="riemann-zeta" element={<RiemannZeta />} />
            </Routes>
        </>
    );
}

export default StuffPage;
