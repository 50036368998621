import React, { useEffect, useState } from 'react';
import styles from '../../styles';
import functionPlot from 'function-plot'
import { complex, Complex, add, multiply, pow, subtract, sin, gamma } from 'mathjs';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            math: any;
            mi: any;
            mo: any;
            msup: any;
            mrow: any;
            mn: any;
            munder: any;
            mfrac: any;
        }
    }
}

const zeta = (s: Complex, terms: number = 500): Complex => {
    s = complex(s);

    if (s.re > 1) {
        let sum: Complex = complex(0);
        for (let n = 1; n <= terms; n++) {
            sum = add(sum, pow(n, multiply(-1, s) as Complex)) as Complex;
        }
        return sum;
    }

    // Use the Dirichlet eta function: η(s) = (1 - 2^(1-s)) * ζ(s) for Re(s) > 0
    if (s.re > 0) {
        let etaSum: Complex = complex(0);
        for (let n = 1; n <= terms; n++) {
            etaSum = add(etaSum, multiply(((n & 1) === 1 ? 1 : -1), pow(n, multiply(-1, s) as Complex))) as Complex;
        }
        let factor: Complex = pow(subtract(1, pow(2, subtract(1, s) as Complex)) as Complex, -1) as Complex;
        return multiply(etaSum, factor) as Complex;  // ζ(s) = η(s) / (1 - 2^(1-s))
    }

    // Functional equation for ζ(s) when Re(s) <= 0
    const pi = Math.PI;
    const oneMinusS: Complex = subtract(1, s) as Complex;
    const gammaFactor: Complex = complex(gamma(oneMinusS));
    const sinFactor: Complex = sin(multiply(pi / 2, s) as Complex);
    const zetaOneMinusS: Complex = zeta(oneMinusS, terms);  // Recursive call

    return multiply(
        multiply(pow(2, s), pow(pi, subtract(s, 1) as Complex)),
        multiply(sinFactor, multiply(gammaFactor, zetaOneMinusS))
    ) as Complex;
}

const linspace = (start: number, end: number, n: number): number[] => {
    if (n < 2) return [start];
    const step = (end - start) / (n - 1);
    return Array.from({ length: n }, (_, i) => start + i * step);
}

const RiemannZeta: React.FC = () => {
    const [im, setIm] = useState(0);

    useEffect(() => {
        const width = 780;
        const height = 480;

        functionPlot({
            target: '#plot',
            width: width,
            height: height,
            grid: true,
            data: [{
                graphType: 'polyline',
                fnType: 'points',
                points: linspace(im - 5, im + 5, 100).map(im => zeta({ re: 0.5, im } as Complex)).map(z => [z.re, z.im])
            }]
        });
    }, [im]);

    return (
        <div style={styles.stuffPanel}>
            <div style={{
                ...styles.stuffPaper,
                width: '800px',
                height: '700px'
            }}>
                <div style={styles.button}>
                    Riemann Zeta Function Plotter
                </div>
                <div id='plot' style={{
                    margin: '12px'
                }} />
                <div style={{ fontSize: '1.5em' }}>
                    Values of the Riemann Zeta Function ζ(s) are plotted
                </div>
                <div style={{ fontSize: '1.5em' }}>
                    <math><mi>s</mi></math> varies from <math><mi>1/2 + {im - 5}i</mi></math> to <math><mi>1/2 + {im + 5}i</mi></math>
                </div>
                <math style={{ fontSize: '1.5em' }}>
                    <mrow>
                        <mi>ζ</mi>
                        <mo>(</mo>
                        <mi>s</mi>
                        <mo>)</mo>
                        <mo>=</mo>
                        <munder>
                            <mo>∑</mo>
                            <mrow>
                                <mi>n</mi>
                                <mo>=</mo>
                                <mn>1</mn>
                            </mrow>
                        </munder>
                        <mfrac>
                            <mn>1</mn>
                            <msup>
                                <mi>n</mi>
                                <mi>s</mi>
                            </msup>
                        </mfrac>
                    </mrow>
                </math>
                <input type='range' min={0} max={1000} value={im} step={1} onChange={event => setIm(parseInt(event.target.value))} />
            </div>
        </div>
    );
}

export default RiemannZeta;
