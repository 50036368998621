import React from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import styles from './styles'

// Import components for each page
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import StuffPage from './pages/StuffPage';

const App: React.FC = () => {
    return (
        <div style={styles.container}>
            <header style={styles.header}>
                <nav style={styles.nav}>
                    <Link to="/" style={styles.button}>Home</Link>
                    <Link to="/about" style={styles.button}>About</Link>
                    <Link to="/contact" style={styles.button}>Contact</Link>
                    <Link to="/stuff" style={styles.button}>Stuff</Link>
                </nav>
            </header>

            <main style={styles.mainPanel}>
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutPage />} />
                    <Route path="/contact" element={<ContactPage />} />
                    <Route path="/stuff/*" element={<StuffPage />} />
                </Routes>
            </main>
        </div>
    );
};

export default App;
